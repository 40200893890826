.hover-state:hover svg path {
    fill: white;
}

.opened-state svg path {
    fill:white;
}


@media (max-width: 767px) {
    .custom-width {
        width: calc(100% - 55px);
    }
}
