.state-hover-color:hover svg path {
    fill: #006046;
}

.button-active svg path{
    fill:#006046;
}

.button-active {
    background-color:white;
}

.btn-hover:hover svg path {
    stroke: green;
    fill: green;
}

@media (max-width: 767px) {
    .btns-color svg path {
        fill: #006046;
    }
    .btns-color:hover svg path {
        fill:white;
    }
    .header-settings {
        height: 55px;
        display:flex;
        flex-direction:column;
        align-items:center;
        transform: scaleY(0);    
        transform-origin: top;
        transition: transform 0.5s linear;
    }
    .header-settings.opened {
        height: 180px;
        transform: scaleY(1); 
    }
    
}
