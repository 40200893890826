.map-container {
    width: 100vw;
    height: 100vh;
}

.ol-scale-line {
    bottom: 80px;
}

/* Locate me button */
.ol-locate-button {
    top: 60%;
    right: 18px;
    position: fixed;
    background-color: #fefefe;
    border-radius: 30px;
    width: 44px;
    height: 44px;
    z-index: 3000;
}

.ol-locate-button:hover {
    background-color: #545454;
}

.ol-locate-button:hover svg path {
    fill: white;
}

.ol-control {
    background-color: transparent;
}
 
/* Zoom to extent button */
.ol-touch .ol-zoom-extent {
    top:45%;
}

.ol-zoom-extent {
    top:500px;
}

.wrapper svg {
    display: inline;
}

.ol-zoom-extent button {
    width: 44px;
    height: 44px;
    background-image: url("../Media/Icons/zoom_extent.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
}

.ol-zoom-extent button:hover {
    background-image: url("../Media/Icons/zoom_extent_hover.svg");
    background-color: #545454;
    color: transparent;
}

.ol-zoom-extent:hover {
    color: #545454;
    outline:none;
}

.ol-zoom-extent button:focus {
    color:transparent;
    outline: none;
}
 
/* Zoom in/Zoom out buttons */
.ol-control {
    position: fixed;
}

.ol-control button {
    background-color: white;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-weight: initial;
    font-size: 28px;
}

.ol-control button:focus {
    outline: none;
}

.ol-control button:hover {
    background-color: #545454;
    outline: none;
}

.ol-zoom button:hover {
    color:white;
}

.ol-zoom .ol-zoom-in { 
    border-radius: 50%;
}

.ol-zoom .ol-zoom-out {
    border-radius: 50%;
}

/* Open layers buttons (Zoom, extent) position */
.ol-zoom { 
    top: 50%;
    right: 18px;
    left: auto;
}

.ol-zoom-extent {
    top: 45%;
    left: auto;
    right: 18px;
}

/*Mobile Menu Layer svg color*/
@media screen and (max-width: 767px){
    .mobile-layer-icon-closed svg path {
        fill: white;   
    }
}

@media screen and (max-width: 427px) {
    .ol-locate-button {
        top: auto;
        bottom: 25px;
    }
    .ol-zoom {
        top: auto;
        bottom: 74px;
    }
    .ol-zoom-extent {
        top:auto;
        bottom: 170px;
    }
    .ol-touch .ol-zoom-extent {
        top:auto;
    }
}